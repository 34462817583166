import { default as _91_46_46_46cms_93uxT4pzk5u4Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93B2c5XQti72Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/3d-projects/[...slug].vue?macro=true";
import { default as index3NeILgV1TaMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/3d-projects/index.vue?macro=true";
import { default as _91_46_46_46slug_93INEr9vsmrhMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/akcii/[...slug].vue?macro=true";
import { default as indexYP1A2t1r2tMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/akcii/index.vue?macro=true";
import { default as _91_46_46_46slug_93j4y0EnqIaOMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/articles/[...slug].vue?macro=true";
import { default as indexezuPLboA2wMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/articles/index.vue?macro=true";
import { default as index90zcrHfdrKMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/authorization/index.vue?macro=true";
import { default as indexV9qZy6oBxvMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/cart/index.vue?macro=true";
import { default as indexBByaH3jejuMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/cart/success/index.vue?macro=true";
import { default as _91_46_46_46slug_93nBqXBIRwOMMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/catalog/[...slug].vue?macro=true";
import { default as indexfhY8bMVRNAMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/catalog/index.vue?macro=true";
import { default as _91_46_46_46slug_93RqZQSzK5QuMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/catalogsearch/[...slug].vue?macro=true";
import { default as resultnvuc0UhA6hMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/catalogsearch/result.vue?macro=true";
import { default as indexY8vUxTupLoMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/clear-page-slider/index.vue?macro=true";
import { default as _91_46_46_46slug_93ZVKWqxR9tPMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/collection/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_935IjMMIgUuJMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/fabriki/[...slug].vue?macro=true";
import { default as indexghhfjUsbrzMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/fabriki/index.vue?macro=true";
import { default as _91_46_46_46slug_93kwyVn7AJxaMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/product/[...slug].vue?macro=true";
import { default as indexnh9MyV0dRLMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/profile/index.vue?macro=true";
import { default as indexAFDuubgI28Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/profile/orders/index.vue?macro=true";
import { default as indexFWf0qxqxMNMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/recently/index.vue?macro=true";
import { default as _91_46_46_46slug_93XxtIaWDi2YMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/sets/[...slug].vue?macro=true";
import { default as indexicFnGRe3R8Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93uxT4pzk5u4Meta?.name ?? "cms",
    path: _91_46_46_46cms_93uxT4pzk5u4Meta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93uxT4pzk5u4Meta || {},
    alias: _91_46_46_46cms_93uxT4pzk5u4Meta?.alias || [],
    redirect: _91_46_46_46cms_93uxT4pzk5u4Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93B2c5XQti72Meta?.name ?? "3d-projects-slug",
    path: _91_46_46_46slug_93B2c5XQti72Meta?.path ?? "/3d-projects/:slug(.*)*",
    meta: _91_46_46_46slug_93B2c5XQti72Meta || {},
    alias: _91_46_46_46slug_93B2c5XQti72Meta?.alias || [],
    redirect: _91_46_46_46slug_93B2c5XQti72Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/3d-projects/[...slug].vue").then(m => m.default || m)
  },
  {
    name: index3NeILgV1TaMeta?.name ?? "3d-projects",
    path: index3NeILgV1TaMeta?.path ?? "/3d-projects",
    meta: index3NeILgV1TaMeta || {},
    alias: index3NeILgV1TaMeta?.alias || [],
    redirect: index3NeILgV1TaMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/3d-projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93INEr9vsmrhMeta?.name ?? "akcii-slug",
    path: _91_46_46_46slug_93INEr9vsmrhMeta?.path ?? "/akcii/:slug(.*)*",
    meta: _91_46_46_46slug_93INEr9vsmrhMeta || {},
    alias: _91_46_46_46slug_93INEr9vsmrhMeta?.alias || [],
    redirect: _91_46_46_46slug_93INEr9vsmrhMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/akcii/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexYP1A2t1r2tMeta?.name ?? "akcii",
    path: indexYP1A2t1r2tMeta?.path ?? "/akcii",
    meta: indexYP1A2t1r2tMeta || {},
    alias: indexYP1A2t1r2tMeta?.alias || [],
    redirect: indexYP1A2t1r2tMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/akcii/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93j4y0EnqIaOMeta?.name ?? "articles-slug",
    path: _91_46_46_46slug_93j4y0EnqIaOMeta?.path ?? "/articles/:slug(.*)*",
    meta: _91_46_46_46slug_93j4y0EnqIaOMeta || {},
    alias: _91_46_46_46slug_93j4y0EnqIaOMeta?.alias || [],
    redirect: _91_46_46_46slug_93j4y0EnqIaOMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/articles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexezuPLboA2wMeta?.name ?? "articles",
    path: indexezuPLboA2wMeta?.path ?? "/articles",
    meta: indexezuPLboA2wMeta || {},
    alias: indexezuPLboA2wMeta?.alias || [],
    redirect: indexezuPLboA2wMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: index90zcrHfdrKMeta?.name ?? "authorization",
    path: index90zcrHfdrKMeta?.path ?? "/authorization",
    meta: index90zcrHfdrKMeta || {},
    alias: index90zcrHfdrKMeta?.alias || [],
    redirect: index90zcrHfdrKMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9qZy6oBxvMeta?.name ?? "cart",
    path: indexV9qZy6oBxvMeta?.path ?? "/cart",
    meta: indexV9qZy6oBxvMeta || {},
    alias: indexV9qZy6oBxvMeta?.alias || [],
    redirect: indexV9qZy6oBxvMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexBByaH3jejuMeta?.name ?? "cart-success",
    path: indexBByaH3jejuMeta?.path ?? "/cart/success",
    meta: indexBByaH3jejuMeta || {},
    alias: indexBByaH3jejuMeta?.alias || [],
    redirect: indexBByaH3jejuMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/cart/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93nBqXBIRwOMMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93nBqXBIRwOMMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93nBqXBIRwOMMeta || {},
    alias: _91_46_46_46slug_93nBqXBIRwOMMeta?.alias || [],
    redirect: _91_46_46_46slug_93nBqXBIRwOMMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexfhY8bMVRNAMeta?.name ?? "catalog",
    path: indexfhY8bMVRNAMeta?.path ?? "/catalog",
    meta: indexfhY8bMVRNAMeta || {},
    alias: indexfhY8bMVRNAMeta?.alias || [],
    redirect: indexfhY8bMVRNAMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93RqZQSzK5QuMeta?.name ?? "catalogsearch-slug",
    path: _91_46_46_46slug_93RqZQSzK5QuMeta?.path ?? "/catalogsearch/:slug(.*)*",
    meta: _91_46_46_46slug_93RqZQSzK5QuMeta || {},
    alias: _91_46_46_46slug_93RqZQSzK5QuMeta?.alias || [],
    redirect: _91_46_46_46slug_93RqZQSzK5QuMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/catalogsearch/[...slug].vue").then(m => m.default || m)
  },
  {
    name: resultnvuc0UhA6hMeta?.name ?? "catalogsearch-result",
    path: resultnvuc0UhA6hMeta?.path ?? "/catalogsearch/result",
    meta: resultnvuc0UhA6hMeta || {},
    alias: resultnvuc0UhA6hMeta?.alias || [],
    redirect: resultnvuc0UhA6hMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/catalogsearch/result.vue").then(m => m.default || m)
  },
  {
    name: indexY8vUxTupLoMeta?.name ?? "clear-page-slider",
    path: indexY8vUxTupLoMeta?.path ?? "/clear-page-slider",
    meta: indexY8vUxTupLoMeta || {},
    alias: indexY8vUxTupLoMeta?.alias || [],
    redirect: indexY8vUxTupLoMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/clear-page-slider/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ZVKWqxR9tPMeta?.name ?? "collection-slug",
    path: _91_46_46_46slug_93ZVKWqxR9tPMeta?.path ?? "/collection/:slug(.*)*",
    meta: _91_46_46_46slug_93ZVKWqxR9tPMeta || {},
    alias: _91_46_46_46slug_93ZVKWqxR9tPMeta?.alias || [],
    redirect: _91_46_46_46slug_93ZVKWqxR9tPMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/collection/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_935IjMMIgUuJMeta?.name ?? "fabriki-slug",
    path: _91_46_46_46slug_935IjMMIgUuJMeta?.path ?? "/fabriki/:slug(.*)*",
    meta: _91_46_46_46slug_935IjMMIgUuJMeta || {},
    alias: _91_46_46_46slug_935IjMMIgUuJMeta?.alias || [],
    redirect: _91_46_46_46slug_935IjMMIgUuJMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/fabriki/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexghhfjUsbrzMeta?.name ?? "fabriki",
    path: indexghhfjUsbrzMeta?.path ?? "/fabriki",
    meta: indexghhfjUsbrzMeta || {},
    alias: indexghhfjUsbrzMeta?.alias || [],
    redirect: indexghhfjUsbrzMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/fabriki/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93kwyVn7AJxaMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93kwyVn7AJxaMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93kwyVn7AJxaMeta || {},
    alias: _91_46_46_46slug_93kwyVn7AJxaMeta?.alias || [],
    redirect: _91_46_46_46slug_93kwyVn7AJxaMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexnh9MyV0dRLMeta?.name ?? "profile",
    path: indexnh9MyV0dRLMeta?.path ?? "/profile",
    meta: indexnh9MyV0dRLMeta || {},
    alias: indexnh9MyV0dRLMeta?.alias || [],
    redirect: indexnh9MyV0dRLMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexAFDuubgI28Meta?.name ?? "profile-orders",
    path: indexAFDuubgI28Meta?.path ?? "/profile/orders",
    meta: indexAFDuubgI28Meta || {},
    alias: indexAFDuubgI28Meta?.alias || [],
    redirect: indexAFDuubgI28Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/profile/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexFWf0qxqxMNMeta?.name ?? "recently",
    path: indexFWf0qxqxMNMeta?.path ?? "/recently",
    meta: indexFWf0qxqxMNMeta || {},
    alias: indexFWf0qxqxMNMeta?.alias || [],
    redirect: indexFWf0qxqxMNMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/recently/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93XxtIaWDi2YMeta?.name ?? "sets-slug",
    path: _91_46_46_46slug_93XxtIaWDi2YMeta?.path ?? "/sets/:slug(.*)*",
    meta: _91_46_46_46slug_93XxtIaWDi2YMeta || {},
    alias: _91_46_46_46slug_93XxtIaWDi2YMeta?.alias || [],
    redirect: _91_46_46_46slug_93XxtIaWDi2YMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/sets/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexicFnGRe3R8Meta?.name ?? "wishlist",
    path: indexicFnGRe3R8Meta?.path ?? "/wishlist",
    meta: indexicFnGRe3R8Meta || {},
    alias: indexicFnGRe3R8Meta?.alias || [],
    redirect: indexicFnGRe3R8Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20241213213313/pages/wishlist/index.vue").then(m => m.default || m)
  }
]